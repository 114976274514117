<template>
  <auth-master-layout left-content-code="registration-left-content">
    <div class="reg-quill-editor mb-2">
      <static-content content-code="registration-sub-title"
        >Create an account and browse a vast list of channels from many
        different countries!
      </static-content>
    </div>
    <b-link
      :to="{ name: 'channels' }"
      class="font-weight-bold d-block text-nowrap"
    >
      <feather-icon icon="ArrowLeftIcon" size="19" />
      Go back to channels
    </b-link>
    <!-- form -->
    <validation-observer ref="registerForm">
      <loader-spinner :loading="loading" class="loader" />
      <b-form class="auth-register-form mt-2">
        <!-- name -->
        <b-form-group label="Name" label-for="register-name">
          <validation-provider
            #default="{ errors }"
            name="name"
            rules="required"
          >
            <b-form-input
              :disabled="loading"
              id="register-name"
              v-model="payload.name"
              name="register-name"
              :state="errors.length > 0 ? false : null"
              placeholder="Name"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- email -->
        <b-form-group label="Email" label-for="register-email">
          <validation-provider
            #default="{ errors }"
            name="email "
            rules="required|email"
          >
            <b-form-input
              :disabled="loading"
              id="register-email"
              v-model="payload.email"
              name="register-email"
              :state="errors.length > 0 ? false : null"
              placeholder="john@example.com"
            />
            <small class="text-danger">
              {{ errors[0] }}
              {{ getApiError("email") }}
            </small>
          </validation-provider>
        </b-form-group>

        <!-- password -->
        <b-form-group label-for="register-password" label="Password">
          <validation-provider
            #default="{ errors }"
            :name="'password' + ' '"
            rules="required"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                :disabled="loading"
                id="register-password"
                v-model="payload.password"
                class="form-control-merge"
                :type="passwordFieldType"
                :state="errors.length > 0 ? false : null"
                name="register-password"
                placeholder="············"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIcon"
                  class="cursor-pointer"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">
              {{ errors[0] }}
              {{ getApiError("password") }}
            </small>
          </validation-provider>
        </b-form-group>

        <!-- password-confirmation -->
        <b-form-group
          label-for="confirm-password"
          label="Password confirmation"
        >
          <validation-provider
            #default="{ errors }"
            name="password confirmation"
            rules="required"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                :disabled="loading"
                id="register-password-confirm"
                v-model="payload.password_confirmation"
                class="form-control-merge"
                :type="passwordFieldType"
                :state="errors.length > 0 ? false : null"
                name="register-password-confirm"
                placeholder="············"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIcon"
                  class="cursor-pointer"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">
              {{ errors[0] }}
              {{ getApiError("password_confirmation") }}
            </small>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Privacy policy"
            rules="required|checked"
          >
            <b-form-checkbox
              :disabled="loading"
              id="privacy"
              v-model="payload.privacy"
              name="privacy"
            >
              I agree to
              <b-link to="/privacy" target="_blank">Privacy Policy</b-link>
            </b-form-checkbox>
            <small class="text-danger">
              {{ errors[0] }}
              {{ getApiError("privacy") }}
            </small>
          </validation-provider>
        </b-form-group>
        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Terms & Condition"
            rules="required|checked"
          >
            <b-form-checkbox
              id="terms"
              v-model="payload.terms"
              name="terms"
              :disabled="loading"
            >
              I agree to
              <b-link to="/terms-and-conditions" target="_blank"
                >Terms & Conditions
              </b-link>
            </b-form-checkbox>
            <small class="text-danger">
              {{ errors[0] }}
              {{ getApiError("terms") }}
            </small>
          </validation-provider>
        </b-form-group>

        <b-button
          variant="primary"
          block
          type="submit"
          :disabled="isAdmin || loading"
          @click.prevent="onSubmit"
        >
          Sign up
        </b-button>
      </b-form>
    </validation-observer>

    <p class="text-center mt-2">
      <span>Already have an account?</span>
      <b-link :to="{ name: 'login' }" :disabled="loading">
        <span>&nbsp;Sign in instead</span>
      </b-link>
    </p>

    <!-- divider -->
    <div class="divider my-2">
      <div class="divider-text">or</div>
    </div>

    <social-login-buttons></social-login-buttons>
  </auth-master-layout>
</template>

<script>
/* eslint-disable global-require */
import LoaderSpinner from "../../layouts/components/TableSpinner.vue";
import StaticContent from "@/layouts/components/static-content-editor/StaticContent";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import {
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BLink,
} from "bootstrap-vue";
import { email, required, checked } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import { postRegistration } from "@/services/api/auth";
import { mapActions } from "vuex";
import AuthMasterLayout from "@/views/auth/AuthMasterLayout";
import { apiErrorHandler } from "@/services/utils/util";
import SocialLoginButtons from "@/layouts/components/buttons/SocialLoginButtons.vue";

export default {
  components: {
    AuthMasterLayout,
    BLink,
    BButton,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
    LoaderSpinner,
    StaticContent,
    SocialLoginButtons,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      payload: {
        name: "",
        userEmail: "",
        password: "",
        passwordConfirm: "",
      },
      loading: false,
      sideImg: require("@/assets/images/pages/register-v2.svg"),
      // validation
      required,
      email,
      checked,
      apiErrors: {},
    };
  },
  computed: {
    isAdmin() {
      return store.getters["auth/isAdmin"];
    },
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/register-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    ...mapActions({
      socialLogin: "auth/socialLogin",
    }),
    getApiError(attribute) {
      return (this.apiErrors?.[attribute] ?? []).join(". ");
    },
    async onSubmit() {
      this.loading = true;
      await this.$refs.registerForm.validate();
      if (!this.payload.privacy) {
        this.$toast.warning("Confirm privacy policy");
        this.loading = false;
        return;
      }
      if (!this.payload.terms) {
        this.$toast.warning("Confirm terms and conditions");
        this.loading = false;
        return;
      }

      this.apiErrors = {};
      if (this.$refs.registerForm.validate()) {
        try {
          await postRegistration(this.payload);
          this.$toast.success("Registered successfully");
          this.$router.replace({ name: "registration-callback" });
        } catch (e) {
          this.apiErrors = e?.response?.data?.message || {};
          apiErrorHandler(e);
        } finally {
          this.loading = false;
        }
      }
    },
    async onSocialAuth(provider) {
      try {
        const data = await this.$auth.authenticate(provider);
        await this.socialLogin({ provider, data });
      } catch (e) {
        apiErrorHandler(e);
      }
    },
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
.loader {
  margin: 10px 0px 10px 45% !important;
}
.reg-quill-editor .static-content-container .static-content-float {
  position: relative;
  left: 0;
  bottom: 0;
}
</style>
