var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('auth-master-layout',{attrs:{"left-content-code":"registration-left-content"}},[_c('div',{staticClass:"reg-quill-editor mb-2"},[_c('static-content',{attrs:{"content-code":"registration-sub-title"}},[_vm._v("Create an account and browse a vast list of channels from many different countries! ")])],1),_c('b-link',{staticClass:"font-weight-bold d-block text-nowrap",attrs:{"to":{ name: 'channels' }}},[_c('feather-icon',{attrs:{"icon":"ArrowLeftIcon","size":"19"}}),_vm._v(" Go back to channels ")],1),_c('validation-observer',{ref:"registerForm"},[_c('loader-spinner',{staticClass:"loader",attrs:{"loading":_vm.loading}}),_c('b-form',{staticClass:"auth-register-form mt-2"},[_c('b-form-group',{attrs:{"label":"Name","label-for":"register-name"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":_vm.loading,"id":"register-name","name":"register-name","state":errors.length > 0 ? false : null,"placeholder":"Name"},model:{value:(_vm.payload.name),callback:function ($$v) {_vm.$set(_vm.payload, "name", $$v)},expression:"payload.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"register-email"}},[_c('validation-provider',{attrs:{"name":"email ","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":_vm.loading,"id":"register-email","name":"register-email","state":errors.length > 0 ? false : null,"placeholder":"john@example.com"},model:{value:(_vm.payload.email),callback:function ($$v) {_vm.$set(_vm.payload, "email", $$v)},expression:"payload.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" "+_vm._s(_vm.getApiError("email"))+" ")])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"register-password","label":"Password"}},[_c('validation-provider',{attrs:{"name":'password' + ' ',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"disabled":_vm.loading,"id":"register-password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"register-password","placeholder":"············"},model:{value:(_vm.payload.password),callback:function ($$v) {_vm.$set(_vm.payload, "password", $$v)},expression:"payload.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" "+_vm._s(_vm.getApiError("password"))+" ")])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"confirm-password","label":"Password confirmation"}},[_c('validation-provider',{attrs:{"name":"password confirmation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"disabled":_vm.loading,"id":"register-password-confirm","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"register-password-confirm","placeholder":"············"},model:{value:(_vm.payload.password_confirmation),callback:function ($$v) {_vm.$set(_vm.payload, "password_confirmation", $$v)},expression:"payload.password_confirmation"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" "+_vm._s(_vm.getApiError("password_confirmation"))+" ")])]}}])})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Privacy policy","rules":"required|checked"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"disabled":_vm.loading,"id":"privacy","name":"privacy"},model:{value:(_vm.payload.privacy),callback:function ($$v) {_vm.$set(_vm.payload, "privacy", $$v)},expression:"payload.privacy"}},[_vm._v(" I agree to "),_c('b-link',{attrs:{"to":"/privacy","target":"_blank"}},[_vm._v("Privacy Policy")])],1),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" "+_vm._s(_vm.getApiError("privacy"))+" ")])]}}])})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Terms & Condition","rules":"required|checked"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"id":"terms","name":"terms","disabled":_vm.loading},model:{value:(_vm.payload.terms),callback:function ($$v) {_vm.$set(_vm.payload, "terms", $$v)},expression:"payload.terms"}},[_vm._v(" I agree to "),_c('b-link',{attrs:{"to":"/terms-and-conditions","target":"_blank"}},[_vm._v("Terms & Conditions ")])],1),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" "+_vm._s(_vm.getApiError("terms"))+" ")])]}}])})],1),_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit","disabled":_vm.isAdmin || _vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_vm._v(" Sign up ")])],1)],1),_c('p',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("Already have an account?")]),_c('b-link',{attrs:{"to":{ name: 'login' },"disabled":_vm.loading}},[_c('span',[_vm._v(" Sign in instead")])])],1),_c('div',{staticClass:"divider my-2"},[_c('div',{staticClass:"divider-text"},[_vm._v("or")])]),_c('social-login-buttons')],1)}
var staticRenderFns = []

export { render, staticRenderFns }